.dropdown-container{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.color-picker{
margin-left: 300px;
}
.middle-manager {
  display: grid; 
  align-items: center;
  height: 100vh; 
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100dvw;
  max-width: 100dvw;
}

.btn-purple{
background-color: var(--main-color)!important;
color: var(--font-color) !important;
}

.add-model{
display: flex;
flex-direction: column;
height: 30px;
justify-content: center;
align-items: center;
}

.dropdown-models{
width: 150px;
margin-top: 10px;
}

.ai-container{
display: flex;
flex-direction: column;
align-items: center;
width: 100dvw;
max-height: 100dvh;
overflow-y: auto;
}

.visual-image:fullscreen {
width: 100%;
height: 100%;
}

.fullscreen-button {
  position: absolute;
  top: 10px;
  
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensure it is above other elements */
  
  margin-top: 10px;
}

.fullscreen-button2 {
  position: absolute;
  top: 10px;
  right: 10px;
 
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensure it is above other elements */
}

.fullscreen-button:hover {
background-color: #0056b3;
}

.container-for-side{
width: 100%;
display: flex;
justify-content: end;

}

.side-text-convo{
margin-right: 100px;
display: flex;
flex-direction: column;
font-size: larger;
background-color: #FFFFFF;
width: 600px;
height: 670px;
align-items: center;
border: 2px solid black; 
box-sizing: border-box;
resize: both;

}

.side-text-convo2{
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  font-size: larger;
  background-color: #FFFFFF;
  width: 600px;
  height: 670px;
  align-items: center;
  border: 2px solid black; 
  box-sizing: border-box;
  resize: both;
  visibility: hidden;
  position: absolute;
  right: 1%;
  top: calc(50% - 335px);
  }

@media (max-width: 768px) {
.card-title {
  font-size: large;
}




.side-text-convo{
  margin-right: -90px;
  display: flex;
  flex-direction: column;
  font-size: larger;
  background-color: #FFFFFF;
  width: 140px;
  height: 670px;
  align-items: center;
  border: 2px solid black; 
  box-sizing: border-box;
  resize: both;
}
}

.images {
  display: flex;
  flex-direction: column; /* Change to column to stack the image and prompt box */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.visual-image {
  position: relative; /* Ensure the child elements can be positioned absolutely */
  width: 800px;
  height: 450px; /* 16:9 aspect ratio */
  background-size: cover;
  background-position: center;
}

@media (min-width: 768px) {
.visual-image {
  max-width: 25vw; /* Max width for desktop */
}
}

.begin-ai{
border-radius: 8px;
border-style: none;
box-sizing: border-box;
color: var(--font-color);
cursor: pointer;
flex-shrink: 0;
font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
font-size: 16px;
font-weight: 500;
height: 4rem;
padding: 0 1.6rem;
text-align: center;
text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
transition: all .5s;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7);
margin-top: 15px;
}

.begin-ai:hover {
box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
transition-duration: .1s;
}

@media (min-width: 768px) {
.begin-ai {
  padding: 0 2.6rem;
}
}

.visual-ai{
}

.conversation-card-body{
max-height: 600px; 
overflow-y: auto;
background-color: white;
width: 95%;
margin-left: 5px;
}

div.card.visual-image {
z-index: 1; 
position: relative; 
}

.prompt-box {
  background-color: lightblue;
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  font-size: larger;
  text-align: center;
  width: auto;
  font-family: "Madimi One", sans-serif;
  width: 500px
}

.prompt-box2 {
  background-color: lightblue;
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  font-size: larger;
  text-align: center;
  width: auto;
  font-family: "Madimi One", sans-serif;
  width: 500px;
  visibility: hidden;
  position: absolute;
  bottom: 10%;
  left: calc(50% - 250px);
}
.custom-joyride-button {
  background: url('./jt-open_eyes_closed_mouth.png') no-repeat center center;
  background-size: contain;
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  border: none;
  cursor: pointer;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}




