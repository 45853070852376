body{
    color: #ffffff;
    font-family: "Madimi One", sans-serif;
}

.StreamCommunicateMain{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

.StreamCommunicateComponents{
    min-width: 1416px;
    width: 1416px;
    overflow-y: hidden;
    overflow-x: visible;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.StreamCommunicateTitle{
    font-size: 55px;
    margin-top: 35px;
    color: #ffffff;
    font-family: "Madimi One", sans-serif;
}

.StreamCommunicatePopUp{
    width: 200px;
    font-size: 20px;
}
.ContainerForControlsAndPreview{
    display: grid;
    grid-template-columns: 1fr 600px 1fr; 
    
    min-width: 1416px;
    width: 1416px;
    gap: 10px;
}
.containerForPreview{
    display: flex;
    flex-direction: column;
 
    align-items: center;
    justify-content: center;
}

.StreamCommunicatePreview{
    width: 600px;
    height: 600px;
    border: black 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
   
}
.StreamCommunicatePreviewText{
color: #ffffff;
font-family: "Madimi One", sans-serif;
}
.StreamCommunicateImage{
    width: 500px;
}


.StreamCommunicateListenButton {
    background-color: #090979;
    color: #ffffff;
    border: 1px solid #03032c;
    width: 90px;
    height: 90px;
    border-radius: 50px;
    font-size: 35px;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px #090979, 0 0 10px #090979, 0 0 20px #090979;
    text-align: center;
    line-height: 90px; /* Match the height of the button to center vertically */
    display: inline-block;
    vertical-align: middle;
    padding: 0; /* Remove padding to ensure proper centering */
}

.StreamCommunicateListenButton:hover {
    box-shadow: 0 0 10px #090979, 0 0 30px #090979, 0 0 50px #090979;
}




/* Control Panel */
.controlItemstream{
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 5px;
}

.resetButtonStream{
    background-color: white;
    border: none;
    color: rgb(0, 68, 255);
}

.StreamCommunicateControlPanel{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
   color: black;
   font-family: "Madimi One", sans-serif;
}

.controlsTitle{
    align-self: center;
}

.streamCharacterControls{
    display: flex;
   
    flex-direction: column;
    height: 100%;
   border: 1px solid black;
 margin:10px;
 padding: 10px;
}

