.Title {
  font-size: 60px;
  color: var(--font-color);
  font-family: "Madimi One", sans-serif;
}

.training-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100svw;
  max-height: 100dvh;
  overflow-y: auto;
}

.display-models {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.text-description {
  overflow-y: auto;
  max-height: 105px;
  min-height: 105px;
  border: 1px white solid;
  padding: 5px;
}

.title-name {
  max-height: 25px;
  min-height: 25px;
  overflow-y: hidden;
}

.model-card {
  height: 540px !important;
  min-height: 540px !important;
  max-height: 540px !important;
}

.display-models > div > div {
  background-color: var(--block-color);
  font-family: "Madimi One", sans-serif;
  color: var(--font-color);
}

.training-container-examples {
  border-width: 3px !important;
  max-height: 40vh;
  overflow-y: auto;
}

.create-btn {
  margin-bottom: 10px;
  margin-left: 20px;
}

.add-btn {
  max-width: 60px;
  justify-self: flex-end;
}

.main-for-page {
  font-family: "Madimi One", sans-serif;
}

.list-group-flush.voice-card .list-group-item.voice-list-item {
  background-color: var(--block-color);
  color: var(--font-color);
  border-top: 2px solid white; /* Your desired color */
}

.gotta-contain-the-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
}

.examples {
  margin-left: -10px;
}

.btn-create {
  background-color: var(--primary-color) !important;
}

.fixed-size-image {
  width: 200px; /* Set the desired width */
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.is-invalid {
  border-color: red;
}

.text-danger {
  color: red;
}
