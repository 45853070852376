.main-page{
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #090979 0%, #514974 46.49122807017544%, #8C7D70 65.78947368421053%, #C9B36C 80.7017543859649%, #F4D869 100%);
   height:150dvh;
    font-family: "Madimi One", sans-serif;
  
  overflow-y: auto;
}

.top-section{
  margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
  min-height: 100dvh;
}

.logo-and-ai{
    align-items: center;
    margin-top: -120px;
    display: flex;
    flex-direction: row;
}
.image-above-a3testdele3{
  filter: brightness(30%);
  top: 0%;
  height: 100dvh;
  position: absolute;
  width: 100dvw;
  z-index: 0;
}
.image-above-a3testdele3 img {
  top: 0%;
  height: 100dvh;
  position: absolute;
  width: 100dvw;
  z-index: 0;
  display: block;
 
 
  -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
}
.bottom-section{
   

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    
   
   
}
.a3testdele3{
  top: 0%;
  height: 100dvh;
  position: absolute;
  width: 100dvw;
  z-index: 0;
}

.button-container{
    display: flex;
  

    align-items: center;
    justify-content: center;
}
.main-text{
   
    
    color: white;
    font-size: 190px;  
    letter-spacing: -7px;
    opacity: 1;
}

.logo{
    width: auto;
    height: 200px;
}

.small-text{
    color: white;
    font-size: 30px;
    width: 50dvw;
    max-width:600px;
   
}
.logo {
    width: auto;
    height: 200px;
    animation: reveal 3s forwards; /* Adjust duration as needed */
  }
  
  @keyframes reveal {
    from {
      clip-path: circle(0% at 50% 50%); /* Start from 0 radius at the center */
    }
    to {
      clip-path: circle(150% at 50% 50%); /* Expand to cover the full image and more */
    }
  }
  .images3{

 height: 100%;
 margin-bottom: 20px;
  }
.another-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.mini-title{
    color: white;
    font-size: 50px;
    font-weight: bold;
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(60px); /* Start from below the original position */
    }
    to {
      opacity: 0.9;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    animation: fadeInUp 1s ease-out forwards;
  }
  







.button-start {
    background-color: initial;
   background-color: #090979;
    border-radius: 5px;
    border-style: none;
    box-shadow: rgba(180, 144, 250, 0.25) 0 1px 1px inset;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Madimi One", sans-serif;
    font-size: 25px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
    z-index: 10;
  }
  
  .button-start:hover {
    opacity: .8;
  }
  
  @media screen and (max-width: 1000px) {
    .button-start {
      font-size: 14px;
      height: 55px;
      line-height: 55px;
      width: 150px;
    }
  }

















.image-text{
    display: flex;
    justify-content: center;
    font-size: 20px;
}

  input[type=radio] {
    display: none;
  }
  
  .carousel-card {
    z-index: 1000;
    position: absolute;
    width: 60%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition:  transform 1s ease, opacity 1s ease;
    cursor: pointer;
  }
  
  .container {
    width: 100%;
    max-width: 800px;
    max-height: 370px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .cards {
    position: relative;
    width: 50%;
    height: 100%;
    margin-bottom: 20px;
  }
  
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  #item-1:checked ~ .cards #song-3, #item-2:checked ~ .cards #song-1, #item-3:checked ~ .cards #song-2 {
    transform: translatex(-40%) scale(.8);
    opacity: .4;
    z-index: 0;
  }
  
  #item-1:checked ~ .cards #song-2, #item-2:checked ~ .cards #song-3, #item-3:checked ~ .cards #song-1 {
    transform: translatex(40%) scale(.8);
    opacity: .4;
    z-index: 0;
  }
  
  #item-1:checked ~ .cards #song-1, #item-2:checked ~ .cards #song-2, #item-3:checked ~ .cards #song-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;
    
    img {
      box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
    }
  }
  
  
  
  
  
  #item-1:checked ~ .cards #song-1, #item-2:checked ~ .cards #song-2, #item-3:checked ~ .cards #song-3 {
      transform: translatex(0) scale(1);
      opacity: 1;
      z-index: 1;
    }
    
    #item-1:checked ~ .cards #song-2, #item-2:checked ~ .cards #song-3, #item-3:checked ~ .cards #song-1 {
      transform: translatex(40%) scale(.8);
      opacity: .4;
      z-index: 0;
    }
    
    #item-1:checked ~ .cards #song-3, #item-2:checked ~ .cards #song-1, #item-3:checked ~ .cards #song-2 {
      transform: translatex(-40%) scale(.8);
      opacity: .4;
      z-index: 0;
    }
  
  