.talking-indicater{
    position: absolute;
    top: 79px;
    transition:  0.3s ease-in-out;
    width: 640px;
    max-width: 70vw;
    height: 643px;
   z-index: 0;
   border: 10px solid rgb(9,9,121,1);
   border-bottom: 0px;
   max-height: 41.5dvh;
   border-radius: 5px;
   background-color: rgb(9,9,121,1);
}

@media (min-width: 768px) {
    .talking-indicater {
      max-width: 50vw;
      height: 638px; 
      max-height: 70dvh;
      width: 640px;
      max-width: 64vw;/* Max width for desktop */
      transition:  0.3s ease-in-out;
      border-radius: 5px;
      background-color: rgb(9,9,121,1);
    }
  }

  @media (min-width: 768px) {
  .talking-indicater.talking {
    transition:  0.3s ease-in-out;
    width: 680px;
    max-width: 90vw; 
    height: 658px;
    max-height: 90dvh;
    top: 60px;/* or adjust as needed */
  }
} 

.talking-indicater.talking {
    transition:  0.3s ease-in-out;
    width: 680px;
    max-width: 80vw; 
    height: 658px;
    max-height: 44.5dvh;
    top: 60px;/* or adjust as needed */
  }


