.full-page-quickstart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.QuickStart-main-container {
    min-width: 1216px !important;
    min-height: 700px !important;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-preview-quickstart {
    width: auto;
    height: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.start-button-quickstart {
    width: 250px;
    height: 150px;
    font-size: 50px !important;
}

.quickstart-title {
    font-size: 60px;
    color: var(--font-color);
    font-family: "Madimi One", sans-serif;
    align-self: center;
}

.hidden-quickstart {
    background-color: gray !important;
    border-color: gray !important;
    opacity: 0.5;
    pointer-events: none;
}

.Starting-Up-Display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #ff4b45;
    position: relative;
}

.starting-up-text {
    font-size: 60px;
    color: var(--font-color);
    font-family: "Madimi One", sans-serif;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.progressbar {
    max-width: 500px;
    width: 100%;
    margin: 30px auto 0;
    height: 30px;
    background: #274545;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

span.progress {
    display: block;
    height: 100%;
    background: #2edf78;
    transition: width 0.3s ease-in-out;
}

.counter {
    font-size: 24px;
    margin-top: 20px;
    color: white;
}

.TransferScreen-quickstart {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ff4b45;
    z-index: 1000;
    visibility: visible;
    animation: lensEffect 2s forwards;
}

@keyframes lensEffect {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
