@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .volume-meter{
    margin-top: 10px;
    margin-bottom: -25px;
  }

  .spinning{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
   
    margin-bottom: 5px;
  }
  .visual-ai.darkened {
    filter: brightness(50%);
}

.start-listening-button{
  position: absolute;
  top: 65px;

}