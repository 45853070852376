/* MAIN COLORS */
:root {
  --signin-primary-color: #ff4740;
  --signin-secondary-color: #ffd52b;
  --signin-bg-color: #f5f5f5;
  --signin-input-bg: rgba(255, 255, 255, 0.7);
  --signin-text-color: #333333;
  --signin-input-border-color: #ff4740;
  --signin-button-text-color: white;
}

/* FORM WRAPPER */
.signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--signin-bg-color);
  background-image: url("../styles/forest-background.png");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.signin-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  z-index: -1;
}

/* FORM CONTENT */
#signin-formContent {
  background-color: var(--signin-input-bg);
  padding: 40px 30px;
  max-width: 400px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* HEADINGS */
.signin-h2 {
  font-family: "Madimi One", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--signin-primary-color);
}

.signin-h2.inactive {
  color: var(--signin-text-color);
  opacity: 0.5;
  cursor: pointer;
}

.signin-h2.active {
  color: var(--signin-primary-color);
  border-bottom: 3px solid var(--signin-secondary-color);
  padding-bottom: 8px;
}

/* INPUT FIELDS */
input[type="text"].signin-input,
input[type="password"].signin-input {
  background-color: var(--signin-input-bg);
  border: 2px solid transparent;
  padding: 15px;
  width: 90%;
  margin: 10px 0;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  color: var(--signin-text-color);
}

input[type="text"].signin-input:focus,
input[type="password"].signin-input:focus {
  border: 2px solid var(--signin-input-border-color);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px rgba(255, 71, 64, 0.5);
}

input[type="text"].signin-input::placeholder,
input[type="password"].signin-input::placeholder {
  color: #bbbbbb;
  font-size: 14px;
}

/* BUTTON STYLES */
.signin-button {
  background-color: var(--signin-primary-color);
  color: var(--signin-button-text-color);
  padding: 15px 80px;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 15px;
  box-shadow: 0px 10px 20px rgba(255, 71, 64, 0.4);
}

.signin-button:hover {
  background-color: var(--signin-secondary-color);
  color: var(--signin-primary-color);
  box-shadow: 0 10px 30px rgba(255, 213, 43, 0.5);
}

.signin-button:active {
  transform: scale(0.97);
  background-color: #e84038;
}

/* BUTTON GROUP */
.signin-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.signin-btn {
  padding: 12px 70px;
  font-size: 14px;
}

.signin-error-message,
.signin-success-message {
  color: var(--signin-primary-color);
  font-size: 14px;
  margin-top: 20px;
}

/* FORGOT PASSWORD */
.signin-forgot-btn {
  background-color: transparent;
  color: var(--signin-primary-color);
  text-transform: none;
  margin-top: 10px;
  font-size: 13px;
}

.signin-forgot-btn:hover {
  color: var(--signin-secondary-color);
  text-decoration: underline;
}

/* ANIMATIONS */
.signin-fadeInDown {
  animation: signin-fadeInDown 1s ease-in-out both;
}

@keyframes signin-fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


