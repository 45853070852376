body {
    overflow-x: auto;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    font-family: "Madimi One", sans-serif;
}

.main-container-for-character-creator {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Madimi One", sans-serif;
}

.character-creator-title {
    margin-bottom: 40px;
    text-align: center;
    font-family: "Madimi One", sans-serif;
}

.character-creator-container-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
    font-family: "Madimi One", sans-serif;
}

.creator-create-box {
    width: 300px;
    background-color: #f7d962;
    height: 350px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #f7d962;
    text-align: center;
    justify-content: space-between; /* Ensure spacing for items */
}

.creator-create-box:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
    background-color: #ffd52b; 
}

.creator-create-box h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #333;
}

.creator-create-box p {
    color: #666;
    font-size: 0.9em;
}

.recommended {
    color: #007BFF;
    font-weight: bold;
    margin-bottom: 20px; /* Ensure it's at the bottom */
}






/* route */

.main-container-for-character-builder-window-top-container{
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
 justify-content: center;
  width: 90vw;
  margin-bottom: 5px;
}

.change-title-h1-reformat{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.title-of-page-creator{
    font-family: "Madimi One", sans-serif;
}

.main-container-for-character-builder{

    width: 100vw;
    height: 100vh;
    overflow-y: auto;
   display: grid;
    grid-template-columns: 50px 1fr 350px;
    font-family: "Madimi One", sans-serif;
    min-width: 900px;
}

.side-control-navigation-right{
  position: absolute;
  right: 0;
    background-color: #f7d962;
    font-family: "Madimi One", sans-serif;
    height: 90dvh;
   overflow-y: hidden;
    display: flex;
    flex-direction: column;
    border: 1px black solid;
    margin-left: 10px;
    justify-self: end;
    width: 300px;
}

.main-visual-preview-area{
   
   display: flex;
   flex-direction: column;
   align-items: center;
   max-height: 90vh;
}

.main-container-for-character-builder-window{
    width: 100vw;
    height: 100vh;
   display: flex;
   flex-direction: column;
    align-items: center;
    padding: 30px;
    
}

.dropdown-toggle-create{
    width: 150px;
}

.visual-preview-area-top{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
 
 
}

.visual-preview-area-middle-extra-container{
    display: grid;
   
  
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84dvh;
    
}

.grid-creator-with-one-item{
    grid-template-columns: repeat(1, 1fr);
}

.grid-creator-with-two-item{
    grid-template-columns: repeat(2, 1fr);
}

.grid-creator-with-four-item{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}


.image-container-creator{
  
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    height: 100%; 
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preview-test-button{
   
    margin-left: 10px;
}
.selected-image{
   max-width: 40%;
   height: auto;
}

.middle-visual-preview-create-grid-item{
    width: 100%;
    height: 100%;   
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: auto;
    flex-direction: column;
    font-size: 18px;
}

.visual-preview-area-middle{
   
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 600px;
}

.add-image-box-and-button{
    font-family: "Madimi One", sans-serif;
    width: 250px;
    height: 130px;
    border: rgb(92, 91, 91) 2px dashed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    
}

.file-input {
    display: none;
  }
  
  .add-image-box-and-button {
    position: relative;
  }
  
  .add-image-box-and-button label {
    display: inline-block;
    padding: 10px;
    background: #ff4740;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .add-image-box-and-button label:hover {
    background: #c02a25;
  }

  .switcher-create{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
  }

  .switcher-button{
    background-color: #f7d96200;
    border: none;
  }

  .switcher-button:hover{
    background-color: #00000036;
  }

.left-create-btn{
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.middle-create-btn{
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-size: 0.82rem;
}

.right-create-btn{
    border-bottom: 1px solid black;
}

.active-tab-create{
    background-color: #ff4740 !important;
}




.switch-custom {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px ;
 
}

/* Hide default HTML checkbox */
.switch-custom input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
  height: 25px ;
}

.slider:hover{
    cursor: pointer;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  top: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff4740;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff4740;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.side-nav-component-right-layout {
  display: flex;
  flex-direction: column;
}

.inner-component-builder-right {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  width: 100%;
  border-bottom: black 1px solid;
}

.dropdown-selection{
    display: flex;
   
}












/* AI tab */

.character-creator-ai-tab{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
 
}

.character-creator-ai-middle{
  display: flex;

  flex-direction: column;
  max-width: 1000px;
 

  
}

.character-creator-ai-description-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: black 3px solid;
  border-radius: 10px;
  height: 200px;
  min-width: 300px;
  margin-top: 10px;
}

.character-creator-ai-description-box{
 height: 100% !important;
  resize: none;
}

.character-creator-ai-right-section-container{
  display: flex;
  flex-direction: column;
  align-items: center;
 margin-top: 50px;
  width: 100%;
 
  
}

.character-creator-ai-right-section-attributes-container-inner{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  padding: 25px;
 
}

.character-creator-ai-right-section-attributes-container{
  margin-top: 50px;
  border: black 3px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 220px;
  border-radius: 10px;
 
}

.character-creator-ai-right-section-attributes-box{
  width: 100%;
  height: 100%;
  background-color: white;
  border: dashed 2px black;
 min-width: 170px;
}

.character-creator-ai-right-section-attributes-select{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
 
  overflow-y: auto;
  width: 250px;
  max-width: 180px;
  min-width: 180px;
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
  max-height: 400px;
 overflow-x: hidden;
}

.character-creator-ai-right-section-attribute{
  width: 150px;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #ff4740;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.character-creator-ai-right-section-attribute-text{
  width: 120px;
  border-right: 2px solid black;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 18px;
}

.btn-custum-creator{
  height: 100%;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background-color: #f7d962;

}

.btn-custum-creator:hover{

  background-color: #c9b150;

}

.character-creator-ai-bottom{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  padding: 20px;
 
  height: 100%;
  align-items: center;
 
}

.character-creator-ai-output{
  resize: none;
  width:500px;
  height: 80px;

}


.attributres-wrapper-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  border: black 3px solid;  
  border-radius: 10px;
  margin-top: 50px;
}


.character-creator-ai-input-text-test{
 
  width: 300px;
  height: 25px;
}

.character-creator-ai-right-section-randomness-container{
  display: flex;
  flex-direction: column;
  border: black 3px solid;
  border-radius: 10px;
 width: 100%;
 justify-content: center;
 align-items: center;
 height: 100%;
 padding: 5px;
}

.creator-slider{
  width: 500px;
  -webkit-appearance: none;
 border-radius: 10px;
 background-color: #f7d962;
 cursor: pointer;

height: 15px;
}

.character-creator-ai-realative{
  width: 80%;
}

.creator-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background:#ff4740;
  height:25px;
  width:25px;
  border-radius: 30px;
}

.character-creator-ai-right-section-randomness-container-image{
  width: 150px;
}

.character-creator-ai-right-section-custom-attributes-box{
  max-width: 160px;
  min-width: 160px;
  margin-left: 25px;
  margin-right: -20px;
  display: flex;
 
  flex-direction: column;
  padding: 5px;
  display: flex;
  align-items: center;
gap: 20px;

border-radius: 10px;
font-size: 18px;
}

.character-creator-ai-input-custom-attribute{
  width: 100%;
  height: 35px;
  border: 2px solid black;
  border-radius: 10px;
}

.custom-attribute-button{
  width: 100%;
  height: 35px;
  border: 2px solid black !important;
  border-radius: 10px;
  background-color: #f7d962 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.input-error{
  border-color: red;
}











/* Voice tab */

.character-creator-voice-main-component {

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  padding: 20px;
  color: white;

}

.character-creator-voice-base-container {
  display: grid;
  grid-template-columns: 3fr 5fr;
 
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}

.character-creator-voice-base-select-container {
  width: 220px;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  border: black 2px solid;
  max-height: 190px;
  flex-direction: column;
  overflow-y: auto;
  font-size: 20px;
  border-radius: 10px;
  
}

.character-creator-voice-base-select-preview {
  display: flex;
  flex-direction: row;
  background-color: #ffffff00;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 35px;
  border-radius: 10px;
}

.character-creator-voice-base-options {
  background-color: #f7d962;
  border: black 1px solid;
  align-items: start;
  display: flex;
  padding: 8px;
  
  
  cursor: pointer;
 
}

.character-creator-voice-base-options:hover {
  background-color: #ddd232;
}

.character-creator-voice-base-waveform {
  background-color: #fff240;
  width: 5px;
  height: 50px;
  border-radius: 20px;
  animation: wave-animation 1s infinite ease-in-out;
  
}

.character-creator-voice-base-waveform:nth-child(odd) {
  animation-delay: 0.2s;
}

@keyframes wave-animation {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}

.character-creator-voice-base-select-preview-container{

  display: flex;
  flex-direction: column;
  
}

.character-creator-voice-base-select-preview-controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  justify-content: center;
}

.character-creator-voice-btn{
  background-color: #f7d962;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 2px solid black;
  margin-bottom: 3px;
}

.character-creator-voice-preview-base{
  width: 220px;
  height: 42px;
  padding-left: 10px;
  border-bottom: 2px solid black;
  color: black;
}

.character-creator-voice-base{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid black;
  border-radius: 10px;
  color: black;
  width: 650px;
}


.character-creator-voice-setting{
 
  border: 3px solid black;
  width: 650px;
  border-radius: 10px;
  margin-top: 40px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  justify-content: center;

}

.character-creator-voice-pitch{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.character-creator-pitch-preview {
  display: flex;
  flex-direction: row;
  background-color: #f7d962;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 120px;
}
.character-creator-pitch-waveform {
  background-color: #ff4740;
  width: 7px;
  border-radius: 20px;
  height: 10px;
  animation: wave-animation-settings var(--animation-duration) infinite ease-in-out;
}

.character-creator-pitch-waveform:nth-child(odd) {
  animation-delay: 0.2s;
}

@keyframes wave-animation-settings {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
































/* attributes tab */
.character-creater-attributes-tab-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.select-attribute-carousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-attribute-carousel {
  width: 200px;
  height: 100px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #f7d962;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  overflow: hidden;
  position: relative;
}

.carousel-content {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(0);
  opacity: 1;
  white-space: nowrap;
}

.carousel-content.left {
  transform: translateX(-100%);
  opacity: 0;
}

.carousel-content.right {
  transform: translateX(100%);
  opacity: 0;
}

.carrot-select-attributes {
  font-size: 70px;
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-bubble {
  width: 300px;
  height: 200px;
  border: 4px solid rgb(0, 0, 0);
  border-radius: 12px;
  align-self: flex-end;
  background-color: white;
}

.character-creator-attributes-image {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.creator-slider {
  width: 100%;
  margin: 20px 0;
}

.slider-labels-container {
  display: flex;
  justify-content: space-between;
  width: 108%;
  
  margin-top: -10px;
 
}

.slider-label {
  text-align: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-marker {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  margin-bottom: 5px;
}












/*                Friends                  */


.grid-friends-container{
  display: grid;
  grid-template-columns: 1fr 3fr;
  
  
  width: 100%;
  justify-content: center;
}


.friends-left{
 
  width: 100%;
  height: 100%;
  border-top: 3px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.friends-right{
  
  width: 100%;
  height: 100%;
  border-top: 3px solid black;
  border-left: 3px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.friends-details-box{
  display: flex;
  flex-direction: column;
  align-items: center;
 
  height: 100%;
  width: 100%;
  border-top: 3px solid black;
  margin-bottom: 10px;
  gap: 10px;
}
.friends-details-box input,
.friends-details-box textarea {
  border: 2px solid black;
  border-radius: 10px;
}


.friend-card-container{
  border-top: 3px solid black;
  width: 100%;
  overflow-y: auto;
  max-height: 340px;
  overflow-x: hidden;
  
}

.friend-card{
  border-bottom: 3px dashed black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.friend-name-display {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
  white-space: normal;
  overflow-wrap: anywhere; /* You can also use 'break-word' */
}
