.home-main-container {
    width: 100vw; /* Full viewport width */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

.home-text-white {
    color: white;
}

.home-heading-large {
    margin-top: 50px;
    font-size: 55px;
    animation: fadeIn 1s ease-in-out;
    padding: 8px;
    margin-top: -3px;
}

.home-heading-medium {
    margin-top: 10px;
    font-size: 35px;
    animation: fadeIn 1s ease-in-out;
    padding: 8px;
}

.home-carousel-container {
    gap: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 1216px;
    overflow: visible; /* Allow overflow to be visible */
    margin-bottom: 30px; /* Add some margin at the bottom */
    animation: slideIn 1s ease-in-out;
    padding: 8px;
}

.home-carousel-item {
    max-width: 100%;
    max-height: 300px;
    min-width: 100%;
    min-height: 300px;
    background-color: yellow;
    border-radius: 10px;
    transition: transform 0.3s;
    overflow: hidden; /* Ensure overflow is hidden */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    
}

.home-carousel-item-inner-middle {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.home-carousel-item-inner-bottom {
    background-color: rgba(31, 31, 31, 0.9);
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 15px;
    color: white;
    font-size: 18px;
    text-align: center;
}

.home-carousel-item:hover {
    transform: scale(1.05);
}

.home-section-container {
    min-width: 1216px;
    width: 1216px;
    margin-bottom: 30px; /* Add some margin at the bottom */
    overflow-y: hidden;
    overflow-x: visible; /* Allow overflow to be visible */
}

.home-realm-item {
    max-width: 400px;
    max-height: 400px;
    min-width: 200px;
    min-height: 300px;
    background-color: blue;
    border-radius: 10px;
    transition: transform 0.3s, background-color 0.3s;
    overflow: visible; /* Ensure overflow is visible on hover */
}

.home-realm-item:hover {
    transform: scale(1.05);
    background-color: lightblue;
}

.home-grid-container {
    overflow: visible; /* Allow overflow to be visible */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    animation: fadeIn 1s ease-in-out;
    padding: 8px;
}

.home-trending-item {
    max-width: 400px;
    max-height: 200px;
    min-width: 200px;
    min-height: 100px;
    border-radius: 10px;
    background-color: orange;
    transition: transform 0.3s, background-color 0.3s;
    overflow: visible; /* Ensure overflow is visible on hover */
}

.home-trending-item:hover {
    transform: scale(1.05);
    background-color: darkorange;
}

.home-create-container {
    display: grid;
    grid-template-columns: 2fr 1fr; /* First column is 2/3, second column is 1/3 */
    gap: 20px;
    height: 300px; /* Adjust as needed */
    overflow: visible; /* Allow overflow to be visible */
    padding: 8px;
}

.home-create-character-container {
    height: 100%;
    display: flex;
    overflow: visible; /* Ensure overflow is visible */
   
}

.home-create-character-card {
    flex: 1;
    display: flex;
    flex-direction: column; /* Ensure the content is arranged vertically */
    height: 100%; /* Take full height */
    transition: transform 0.3s;
    overflow: visible; /* Ensure overflow is visible on hover */
    
  
}

.home-create-character-card:hover {
    transform: scale(1.02);
}

.card {
    width: 100%;
    height: 100% !important; /* Ensure the card takes the full height */
}

.home-full-width {
    width: 100%;
}

.home-voice-realm-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space evenly */
    gap: 10px;
    height: 100%; /* Ensure it takes the full height of its parent */
    animation: fadeIn 1s ease-in-out;
    overflow: visible; /* Allow overflow to be visible */
   
}

.home-full-width {
    transition: transform 0.3s;
   
}

.home-full-width:hover {
    transform: scale(1.04);
}

.home-carousel-item:nth-child(2) .home-carousel-item-inner-middle {
    background-image: url('./tutorial-image-abstract.png');
}

.home-carousel-item:nth-child(1) .home-carousel-item-inner-middle {
    background-image: url('./version-abstract-image.png');
}

.home-carousel-item:nth-child(3) .home-carousel-item-inner-middle {
    background-image: url('./info-card-abstract.png');
}

/* Keyframes for animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
