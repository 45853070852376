.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: var(--secondary-color);
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

  .voice-card{
    margin-top: 10px;
  }


  .list-group-flush.voice-card .list-group-item.voice-list-item {
    background-color: var(--block-color);
    color: var(--font-color);
    border-top:  2px solid white  ; /* Your desired color */
  }
  
  .test-audio-btn{
    display: flex;
 
    flex-direction: column;
  }

  .test-audio-btn > button{
    height: 40px;
    width: 60px;
    margin-bottom: 10px;
  }

  .voice-player-preview{
    margin-top: 10px;
    width: 260px;
  }