.settings-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .settings-item {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .settings-item label {
    font-weight: bold;
    color: #555;
  }
  
  .settings-item span {
    color: #777;
  }
  
  .settings-form {
    display: flex;
    flex-direction: column;
  }
  
  .settings-input {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .settings-button {
    padding: 10px;
    background-color: #5c0bdf;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .settings-button:hover {
    background-color: #7535db;
  }
  
  .settings-message {
    margin-top: 15px;
    color: green;
    text-align: center;
  }
  