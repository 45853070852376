@media (min-width: 768px) {
.container-side{
    width: 300px;
    min-height: 100dvh;
    height: 100%;
    background: var(--main-color);
    font-family: "Madimi One", sans-serif;
    transition: 0.5s;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    -webkit-box-shadow: 8px 5px 30px -1px #ff4740;
    -moz-box-shadow: 8px 5px 30px -1px #ff4740;
    box-shadow: 8px 5px 30px -1px #ff4740;
    justify-content: start;
    display: grid;
    flex-direction: column;
    z-index: 3;
    position: absolute;
   
}
}

.container-side {
  min-height: 100dvh;
  height: 100%;
        height: 100dvh;
        background: var(--main-color);
        font-family: "Madimi One", sans-serif;
        transition: 0.5s;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        -webkit-box-shadow: 8px 5px 30px -1px #ff4740;
        -moz-box-shadow: 8px 5px 30px -1px #ff4740;
        box-shadow: 8px 5px 30px -1px #ff4740;
        justify-content: start;
        display: grid;
        flex-direction: column;
        z-index: 3;
        position: fixed;
        

    }
  
    .dropdown-item-new{
    
      font-size: 17px;
      margin-left: 50px;
      background-color: rgba(240, 248, 255, 0)!important;
      border: none !important;
      color: white !important;
      font-size: 20px;
        animation: fadeInEffect 0.6s ease forwards;
       
    }


    .dropdown-item-new::after{
    
      font-size: 17px;
      margin-left: 50px;
 
      border: none !important;
      color: white !important;
      font-size: 20px;
      animation: fadeInEffect 0.6s ease forwards;
    }

    .dropdown-menu-new{
   
      background-color: rgba(240, 248, 255, 0)!important;
      
      border: none !important;
      animation: fadeInTopToBottom 0.5s ease forwards;
      color: white !important;
  
    }

    

    @keyframes fadeInTopToBottom {
      0% {
        clip-path: inset(0 0 100% 0); /* Start with the element clipped from the top */
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        clip-path: inset(0 0 0 0); /* End with the element fully visible */
      }
    }

    @keyframes fadeOutTopToBottom {
      0% {
        clip-path: inset(0 0 0 0); /* Start with the element fully visible */
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        clip-path: inset(0 0 100% 0); /* End with the element clipped from the top */
        opacity: 0;
      }
    }
    

.title-side{
   align-self: center;
   margin-right: 15px;
   font-size: 50px;
   color: var(--font-color);
   margin-bottom: 30px;
   animation: fadeInEffect 0.6s ease forwards;
  
}

.dropdown-item:hover{
  background-color: #ff4b45 !important;
  color: white !important;
}

@keyframes fadeInEffect{
  0% {
    clip-path: inset(0 100% 0 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
.side-nav-icon{
  
  width: 50px;
}

.side-button{
  display: flex;
  align-items: center;
    border: none;
    background-color: transparent;
    color: var(--font-color);
    font-size: 30px;
    animation: fadeInEffect 0.6s ease forwards;
   max-height: 50px;
  min-width: 300px;
}

.logo-side-nav{
  animation: fadeInEffect 0.6s ease forwards;
}

.btn-container{
   height: 50dvh;
    display: grid;
    grid-template-columns: 1fr; /* This creates one column */
    grid-template-rows: repeat(6, auto); 
    align-items: start;
    margin-left: 10px;
  
  
}

.btn-container i{
  margin-right: 15px;
}

.line{
    width:90%;
    height: 2px;
    background-color:var(--secondary-color);
    margin-bottom: 10px;
    margin-top: -18px;
    animation: fadeInEffect 0.6s ease forwards;
    
}
.btn-signout{
  background-color: var(--secondary-color)!important;
}

.container-side.closed {
    width: 0; /* Adjust based on your design */
    /* You might want to adjust padding, visibility of text, etc., as well */
  }
  
  .closebtn {
    width: 35px;
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: var(--font-color);
    position: absolute;
    top: 50vh;
    left: 280px;
    cursor: pointer;
    z-index: 10;
    transition:  0.5s ; /* Smooth transition for transform changes */
    border:none;
    height: 50px;
    border-radius: 10px;
    position: fixed;
  }

  .rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }
  
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
  
   
  .sign-out{
    width: 270px;
    animation: fadeInEffect 0.6s ease forwards;
  }

  .listening-audio-meter{
display: flex;
flex-direction: column;

  }

  


  .usage-circle-container {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    transition: all 0.5s ease; /* Smooth transition */
  }
  
  .usage-circle-container.closed {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  .usage-circle {
    transform: rotate(-90deg); /* Rotates the circle for the 'top-to-bottom' fill effect */
    transition: height 0.5s ease, width 0.5s ease; /* Smooth transition for size */
  }
  
  .circle-background {
    fill: none;
    stroke: #d9d9d9; /* Light grey for background */
  }
  
  .circle-progress {
    fill: none;
    stroke: var(--secondary-color); /* Red progress color */
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s, stroke-width 0.35s ease; /* Smooth transition for progress */
  }
  
  .usage-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: var(--font-color);
    transition: opacity 0.5s ease; /* Smooth opacity transition */
  }
  