.main-for-page-realms {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.realms-title {
    font-size: 60px;
    color: var(--font-color);
    font-family: "Madimi One", sans-serif;
    align-self: center;
}

.create-realms-jr {
    display: flex;
    flex-direction: column;
    align-self: left !important;
    margin-top: 20px;
}

.realms-inner-container {
    display: inline;
    width: 1216px;
    flex-direction: column;
}

.card-main-box-realms {
    display: grid;
    background-color: white;
    max-width: 593px;
    min-width: 593px;
    max-height: 209.81px;
    min-height: 209.81px;
    border-radius: 5px;
    grid-template-columns: 220px 373px;
}

.card-main-realms-left {
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    max-height: 209.81px;
    min-height: 209.81px;
    max-width: 220px;
    min-width: 220px;
    padding: 5px;
    
}

.card-main-realms-right {
    position: relative; /* Added position relative to the right container */
    background-color: yellow;
    border-radius: 5px;
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    max-height: 209.81px;
    min-height: 209.81px;
    overflow-y: hidden;
    overflow-x: hidden;
    max-width: 373px;
    min-width: 373px;
}

.image-container {
   
}

.world-image {
    height: 100%;
    width: 100%;
       
}

.character-image {
    position: absolute; /* Position the character image absolutely */
    top: 50%; /* Adjust to center the image vertically */
    left: 50%; /* Adjust to center the image horizontally */
    transform: translate(-50%, -50%); /* Center the image */
    height: auto;
    width: auto;
    max-height: 205px;
    max-width: auto;
}

.realms-card-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 10px;
}

.realms-modal {
    min-width: 1216px !important;
    min-height: 700px !important;
    overflow-y: auto;
}

.realms-input-name {
    max-width: 500px !important;
}

.uploaded-image, .model-image {
    height: 600px;
    width: auto;
}
