:root {
  --main-color:  #ff4b45 ;            /* #090979; old purple*/
  --secondary-color: #fcfd95;  /* #F4D869 */
  --font-color: #f4f4f4;
  --block-color: #4B5563
}
/*  background: linear-gradient(135deg, #ffcc80 0%, #ff6f61 100%); salmon */
.main-window{
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100dvh;
  background: linear-gradient(135deg, #fd605b  0%, #fcfd95 100%);
  /*
  first background-color: rgb(244,216,105);
second background-color: rgb(236, 196, 34);
third background-color: rgb(233, 198, 57);
fourth background-color: rgb(255, 207, 15);
  background: rgb(102,84,105);
  background: linear-gradient(90deg, rgba(102,84,105,1) 0%, rgba(189,155,46,1) 43%, rgba(252,207,3,1) 100%);
  */
  width: 100%;
}

body{
  font-family: "Madimi One", sans-serif;
  background-color: black;
  font-style: normal;
  width:100%;
  margin: 0;
  overflow-x: hidden;
 
}

/*

Banana Yellow: For vibrancy and to highlight interactive elements.

RGB: (252, 207, 3) #FCCF03
Wolf Grey: As a neutral base or background, providing contrast for the yellow.

RGB: (75, 85, 99) #4B5563
Midnight Blue: To add depth and convey trust and stability.

RGB: (25, 42, 86) #192A56
Silver Sand: For secondary backgrounds and to soften the overall look.

RGB: (191, 193, 194) #BFC1C2
Electric Blue: To highlight important features or calls to action, adding a futuristic feel.

RGB: (0, 145, 234) #0091EA

  background: hsla(274, 65%, 29%, 1);

  background: linear-gradient(180deg, hsla(274, 65%, 29%, 1) 0%, hsla(189, 52%, 5%, 1) 100%);
  
  background: -moz-linear-gradient(180deg, hsla(274, 65%, 29%, 1) 0%, hsla(189, 52%, 5%, 1) 100%);
  
  background: -webkit-linear-gradient(180deg, hsla(274, 65%, 29%, 1) 0%, hsla(189, 52%, 5%, 1) 100%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#501A7A", endColorstr="#061113", GradientType=1 );
*/