.main-twitch-tab-component{
    width: 100vw; /* Full viewport width */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    overflow-x: auto;
}

.twitch-tab-items-cotnainer{
    min-width: 816px;
    max-width: 816px;
   display: flex;
   flex-direction: column;
   align-items: center;
   
}

.twitch-tab-title{
    margin-top: 50px;
    font-size: 55px;
    animation: fadeIn 1s ease-in-out;
    padding: 8px;
    margin-top: -3px;
}

.connect-twitch-button{
    background-color:blueviolet !important;
    color: white !important;
    align-self: center;

}

.regular-container-twitch{
    display: flex;
    align-items: center;
    gap: 50px;
    margin-right: -5px;
}

.Twitch-tab-create-reward{
  background-color: #FF4B45;
  border-radius: 10px;
 padding: 10px;
 margin-top: 10px;
 margin-bottom: 10px;
}

.input-twitch-cost{
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.twitch-input-required-checkbox{
    margin-left: 5px;
    cursor: pointer;
}

.preset-reward-twitch{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

.preset-reward-item{
display: inline;
border-radius: 10px;
}

.preset-reward-twitch-container{
    background-color: #FF4B45;
    border-radius: 10px;
   padding: 10px;
   margin-top: 30px;
   margin-bottom: 10px;
}

.custom-rewards-container{
    background-color: #FF4B45;
    border-radius: 10px;
   padding: 10px;
   margin-top: 30px;
   margin-bottom: 10px;
}